// 
// Initialize plugins and behaviors on document ready
// 
$(function () {
  navbar.init();
  tooltip.init();
  swiper.init();
  lazyLoading.init();
  jarallax.init();
}); // 
// Jarallax on chrome
// 

var jarallax = {
  init: function init() {
    var is_chrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (is_chrome) {
      $('.jarallax').jarallax({
        speed: 0.75
      });
    }
  }
}; // 
// Add extra behavior to the navbar
// 

var navbar = {
  init: function init() {
    if (!window.utils.isMobile()) {
      this.initDropdownHover();
    } // prevent dropdown link click to hide the dropdown


    $('.navbar .dropdown-item').click(function (e) {
      e.stopPropagation();
    });
  },
  // show/hide dropdown on hover
  initDropdownHover: function initDropdownHover() {
    var $dropdowns = $('.navbar .dropdown');
    $dropdowns.each(function (index, item) {
      var $item = $(this);
      $item.hover(function () {
        $item.find("> .dropdown-bs-toggle").attr("aria-expanded", true);
        $item.find("> .dropdown-menu").addClass("show");
      }, function () {
        $item.find("> .dropdown-bs-toggle").attr("aria-expanded", false);
        $item.find("> .dropdown-menu").removeClass("show");
      });
    });
  }
}; //
// Initialize a Swiper instance via data-toggle="swiper"
// and passing the options object via data-options
//

var swiper = {
  init: function init() {
    $("[data-toggle='swiper']").each(function (index, item) {
      new Swiper(item, $(item).data('options'));
    });
  }
}; //
// Initialize Bootstrap tooltip plugins
//

var tooltip = {
  init: function init() {
    $('[data-toggle="tooltip"]').tooltip();
  }
}; // 
// Initialize lazy loading polyfill
//

var lazyLoading = {
  init: function init() {
    if ('loading' in HTMLImageElement.prototype) {
      var images = document.querySelectorAll("img.lazyload");
      images.forEach(function (img) {
        img.src = img.dataset.src;
      });
    } else {
      var script = document.createElement("script");
      script.async = true;
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.2.0/lazysizes.min.js";
      document.body.appendChild(script);
    }
  }
}; // 
// Extra Helpers
// 

window.utils = {
  isMobile: function isMobile() {
    if (window.innerWidth <= 992) {
      return true;
    } else {
      return false;
    }
  }
};